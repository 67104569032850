/*GLOBAL*/
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Oswald:400);

.battles-screen {
  margin: 0;
  width: 100%;
  min-height: 100vh;
  font-family: 'Open Sans';
  background: url("../img/gameBackground.png") no-repeat;
  background-size: cover;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.battles-screen {
  width: 100%;
}

/*MAIN*/
main {
  display: flex;
  flex-direction: column;
}

.no-game-window {
  margin-top: 200px;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.card-bloc {
  display: flex;
  width: 100%;
  margin: 30px auto 10px auto;
  flex-direction: column;
}

.player-card {
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: pointer;
}

.card-head {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  background: linear-gradient(266.42deg, #0ABB31 0%, #206B0D 100%);
  border-radius: 10px 10px 0px 0px;
}

.card-head span {
  font-family: 'Oswald';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: white;
  margin-right: 32px;
}

.card-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  background: #243726;
  border-radius: 0px 0px 10px 10px;
}

.battler-players-card {
  display: flex;
  flex-direction: row;
}

.card-game-info {
  margin-right: 30px;
}

.battles-screen-vs {
  margin: 0 20px;
  font-family: Oswald;
  font-size: 16px;
  text-align: center;
  align-self: center;
}

.exit-button {
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background-color:#25292C;

}

.exit-button a {
  color: white;
  text-align: center;
  text-decoration: none;
}