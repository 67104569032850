/*GLOBAL*/
@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

#root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

body {
  margin: 0;
}

.auth-screen {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans';
  margin: 0;
  background: url("../img/loginBackgroudn.png") no-repeat;
  background-size: cover;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus-visible {
  outline: none;
}

.container {
  max-width: 402px;
}

h1 {
  margin-top: 38px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 40px;
}

h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.extra-orange {
  color: #F65932;
}

a {
  text-decoration: none;
}

/*INDEX.HTML*/
/*HEADER*/
header {
  margin-top: 30px;
  width: 100%;
}

/*MAIN*/
main {
  width: 100%;
}

.wrong-login {
  margin: 0 0 30px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #FF0000;
}

.input-block {
  width: 100%;
}

main div input {
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 10px;
  color: white;
  font-weight: 400;
  font-size: 16px;
  background: none;
  border: 0;
  border-bottom: 1px solid #F65932;
}

.input-block input:first-child {
  margin-bottom: 30px;
}

.forgot-pass {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.forgot-pass a {
  color: white;
  text-decoration: none;
}

.forgot-pass a h3 {
  margin-top: 0;
  margin-bottom: 30px;
}

.btn-block {
  width: 100%;
}

.btn-block div {
  width: 100%;
}

.btn-block div {
  text-align: center;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 5px;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  font-family: 'Open Sans';
}

.login-btn {
  color: white;
  margin-bottom: 30px;
  background: #F65932;
  box-shadow: 0px 10px 14px rgba(246, 89, 50, 0.3);
  text-decoration: none;
}

.reg-btn {
  color: #F65932;
  background: rgba(255, 255, 255, 0.1);
}

/*FOOTER*/
footer {
  width: 100%;
}

footer div h3 {
  text-align: center;
  color: #9BAABD;
}

footer div a {
  color: #F65932;
  text-decoration: none;
}

/*REGISTRATION.HTML*/
/*MAIN*/
.reg-input-block {
  margin-bottom: 30px;
}
