/*GLOBAL*/
@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Oswald:400);

.game-screen {
  width: 100%;
  min-height: 100vh;
  font-family: 'Open Sans';
  background: url("../img/gameBackground.png") no-repeat;
  background-size: cover;
  color: white;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*HEADER*/
.game-screen-header {
  margin-top: 0;
  margin-bottom: 0;
}

.game-header {
  width: 100%;
  background: #0c484e;
}

.header-btn {
  min-height: 30px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  background: #20A39E;
}

.header-btn span {
  padding: 0 19px;
  color: white;
}

.lobby-info {
  margin: 0 auto;
  min-height: 30px;
}

.lobby-info h2 {
  margin: 0;
  font-family: 'Oswald';
  font-weight: 500;
  font-size: 18px;
}

.lobby-info h2 span {
  border-left: 1px solid #3c6d72;
  padding-left: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

/*MAIN*/
main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-bloc {
  height: 25vw;
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
}

.test {
  display: flex;
  width: 90vw;
  height: 100%;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-grow: 1;
}


.game-camera {
  margin-right: -7vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.game-camera:nth-child(2) {
  margin-right: 0;
}

.shotOwner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  max-height: 100px;
  height: auto;
  background: linear-gradient(90deg, rgba(246, 89, 50, 0.6) 0%, rgba(246, 89, 50, 0) 48.12%), #25292C;
}

.shotOpponent {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  max-height: 100px;
  height: auto;
  background: linear-gradient(270deg, rgba(32, 163, 158, 0.6) -0.08%, rgba(24, 106, 106, 0) 47.26%), #25292C;
}

.score-table {
  /*height: 56%;*/
  margin: auto 0;
}

.score-table-line {
  min-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.score-table-line div {
  min-height: 22px;
}

.score-table-item {
  max-width: 62px;
  margin-right: 1px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
}

.score-table-item div:nth-child(1),
.score-table-item-2 div:nth-child(1) {
  background-color: #343D49;
}

.score-table-item div:nth-child(2),
.score-table-item-2 div:nth-child(2) {
  background-color: #BDC3CC;
}

.score-table-item div:nth-child(3) {
  background-color: #70362B;
}

.score-table-item-2 {
  max-width: 62px;
  margin-right: 1px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
  background-color: #186A6A;
}

.score-table-shoots {
  min-height: 22px;
}

.table-item-total div, .table-item-total2 div {
  min-height: 22px;
}

.table-item-total div:nth-child(odd) {
  background-color: #70362B;
}

.table-item-total2 div:nth-child(odd) {
  background-color: #186A6A;
}

.table-item-total div:nth-child(2), .table-item-total2 div:nth-child(2)  {
  background: none;
}

.game-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 2px;
}

.game-info-bloc {
  display: flex;
  flex-direction: column;
  max-width: 44.5vw;
  padding-left: 4.5vw;
  height: 100%;
}

.back-to-battles {
  max-height: 50px;
  width: 100%;
  margin-bottom: 2px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #F65932;
  border-radius: 5px 5px 0 0;
  font-size: 24px;
  cursor: pointer;
}

/*Avatar*/

.user-avatar {
  display: flex;
  flex-direction: row;
}

.user-avatar:nth-child(2) {
  flex-direction: row-reverse;
}

.user-avatar-info {
  margin: 0 10px;
  align-self: center;
}

.user-avatar-info:nth-child(2) {
  margin-left: 10px;
}

.user-avatar-info div:nth-child(2) {
  color: #F65932;
}

.user-avatar-img {
  position: relative;
  display: flex;
}

.user-avatar-img img {
  border-radius: 100px;
  max-width: 65px;
}

.user-avatar-img svg {
  position: absolute;
  z-index: 0;
  max-height: 100%;
  max-width: 100%;
}

.user-avg-score {
  position: absolute;
  bottom: 3px;
  left: -7px;
  min-width: 33px;
  border-radius: 14px;
  background-color: #000B1B;
  text-align: center;
}

.user-flag {
  position: absolute;
  bottom: 0;
  right: -5px;
}

.user-flag img {
  max-width: 22px;
  border-radius: 100px;
}

.games-win {
  margin-left: 10px;
  align-self: center;
  font-family: Oswald;
  font-size: 30px;
}

.games-win2 {
  margin-right: 10px;
  align-self: center;
  font-family: Oswald;
  font-size: 30px;
}

.games-win:nth-child(2) {
  margin-left: 0;
  margin-right: 10px;
}